const TYPES = ({
  clearResult: 'CLEAR_POLLS_DETAILS',
  detailsData: 'GET_POLLS_DETAILS',
  detailsError: 'GET_POLLS_DETAILS_ERROR',


  loadingPostForm: 'LOADING_POST_FORM',
  postFormSuccess: 'POST_FORM_SUCCESS',
  changeFormField: 'CHANGE_FORM_FIELD',
})

export default TYPES;
