import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Analytics } from '../../components';
import { Home } from '../../modules/home/pages';

export default function Routes() {
  return (
    <Router>
      <Analytics>
        <Route exact path='/:identifier' component={Home} />
      </Analytics>
    </Router>
  )
}
