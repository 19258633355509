import axiosGlobalInstance from './http-instance';

const answer = payload => {
  const { sigPayload, signatureToken } = payload;

  return axiosGlobalInstance({
    method: 'POST',
    url: '/survey.answer.php',
    data: { sigPayload },
    headers: {
      'x-signature-token': signatureToken
    }
  });
}

export default answer;
