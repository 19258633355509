import TYPES from '../actions/types';

const INITIAL_STATE = {
  chosenOption: null,

  loadingPostForm: null,
  postDataSuccess: null,
}

const formVoteReducer = function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case TYPES.changeFormField:
      return { ...state, chosenOption: action.payload }

      case TYPES.postFormSuccess:
        return { ...state, postDataSuccess: true }

    case TYPES.loadingPostForm:
      return { ...state, loadingPostForm: !state.loadingPostForm };

    default:
      return state;
  }
}

export default formVoteReducer;
