import TYPES from './types';
import { answer } from '../../../api';
import { encryptAes, setCookie, getCookie } from '../../../utils';

const loadingPost = () => ({ type: TYPES.loadingPostForm });
const successPost = () => ({ type: TYPES.postFormSuccess });

const voteRegistred = (identifier) => {
  return getCookie(identifier) ?? null;
}

export const createAnswer = params => {
  const { payload, header } = params;

  const sigPayload = encryptAes(payload);
  const signatureToken = encryptAes(header);

  return dispatch => {
    dispatch(loadingPost());

    if(voteRegistred(payload.identifier)) {
      return [
        dispatch(loadingPost()),
        dispatch(successPost()),
      ];
    }

    answer({ sigPayload, signatureToken})
      .then(() => {
        const cDate = new Date();

        setCookie(
          payload.identifier,
          sigPayload,
          {
            expires: new Date(cDate.getFullYear() + 1, cDate.getMonth(), cDate.getDate()),
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            path: '/',
          }
        );

        dispatch(loadingPost());
        dispatch(successPost());
      })
      .catch(responseFail => {
        dispatch(loadingPost());
        dispatch(successPost());
      })
  }
}

export const changeChosenOption = value => ({
  type: TYPES.changeFormField,
  payload: value,
});
