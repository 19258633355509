
import TYPES from './types';
import { getPolls } from '../../../api';

const getDetails = identifier =>
  getPolls(identifier)
    .then(response => ({
      type: TYPES.detailsData,
      payload: response,
    }))
    .catch(() => {
      return {
        type: TYPES.detailsError
      }
    })

export const loadData = identifier => {
  return [
    getDetails(identifier),
  ]
};

export const clearDetails = () => ({
  type: TYPES.clearResult,
})
