import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';

function Placeholder(props) {
  const {
    title = null,
    message = null,
  } = props;

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          direction='column'
          align='center'
          justify='center'
          height='100vh'
        >
          <Box
            align='center'
            width='medium'
            pad={ size === 'small' ? 'large' : 'small' }
          >
            {title && (<Box margin={{ bottom: 'small' }}>
              <Text size='xlarge' textAlign='center'>
                {title}
              </Text>
            </Box>)}

            {message && (<Box margin={{ top: 'none', bottom: 'medium' }}>
              <Text size='small' color='dark-5' textAlign='center'>
                {message}
              </Text>
            </Box>)}
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Placeholder;
