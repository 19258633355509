import axios from 'axios';

let axiosInstance;

const axiosGlobalInstance = () => {
  if(!axiosInstance) {
    axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_HOST_API,
      timeout: 10000,
    });
  }

  return axiosInstance;
}

export default axiosGlobalInstance();
