const customThemeBrand = {
    global: {
      colors: {
        brand: {
          dark: '#3068a2',
          light: '#3068a2'
        },
        border: {
          light: 'rgba(0, 0, 0, 0.15)',
        }
      },
  
      drop: {
        border: { radius: '.3rem' },
        shadowSize: 'medium',
      },
  
      breakpoints: {
        small: {
          value: 768
        },
        medium: {
          value: 992
        },
        large: {
          value: 1200
        },
        xlarge: {
          value: 3000
        }
      },
  
      input: {
        weight: 400
      },
  
      focus: {
        border: { color: 'none' }
      }
    },
  
    radioButton: {
      size: '18px',
    },
  
    anchor: {
      fontWeight: 400
    },
  
    button: {
      border: {
        radius: '.3rem'
      },
  
      size: {
        small: {
          border: {
            radius: '.3rem'
          },
          pad: {
            horizontal: '15px'
          },
        },
        medium: {
          border: {
            radius: '.3rem'
          }
        },
        large: {
          border: {
            radius: '.3rem'
          }
        },
      },
    },
  }
  
  export default customThemeBrand;
  