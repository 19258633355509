import axiosGlobalInstance from './http-instance';

const getPolls = identifier => {
  const formattedParams = {
    identifier,
  };

  return axiosGlobalInstance({
    method: 'GET',
    url: '/polls.php',
    params: formattedParams,
  });
}

export default getPolls;
