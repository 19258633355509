import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { ResponsiveContext, Box, Text, RadioButton, Button, Anchor } from 'grommet';
import { Placeholder } from '../../../components';

import { clearDetails, loadData } from '../actions/details';
import { createAnswer, changeChosenOption } from '../actions/form-vote';

class Home extends Component {
  constructor(props) {
    super(props);

    const { identifier } = this.props.match.params;
    const { clearDetails, loadData } = this.props;

    clearDetails();
    loadData(identifier);
  }

  handlerPost = formParams => {
    const { createAnswer } = this.props;
    createAnswer(formParams);
  }

  render() {
    const {
      detail,
      getDetailError,

      chosenOption,
      loadingPostForm,
      postDataSuccess,
      changeChosenOption,
    } = this.props;

    const { identifier } = this.props.match.params;

    if(!getDetailError && !detail) {
      return <Placeholder
        title='Carregando...'
        message='Aguarde que estamos preparando as coisas :)'
      />
    }

    if(getDetailError) {
      return <Placeholder
        title='Opss...'
        message={`A enquete (código: ${identifier}) que esta buscando não existe mais ou já foi encerrada.`}
      />
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box
            background={detail.color}
            align='center'
            justify='center'
            height='100vh'
            pad={{ vertical: 'small', horizontal: 'small' }}
            gap={ size === 'small' ? 'medium' : 'small' }
          >
            <Box
              width='medium'
              background='white'
              round='xsmall'
              flex={{ shrink: 0 }}
            >
              {postDataSuccess ? (
                <Box
                  pad={
                    size === 'small' ? 'large' : 'medium'
                  }
                >
                  <Text size='large' textAlign='center'>
                    {detail.thanksText || 'Obrigado por participar dessa enquete!'}
                  </Text>
                </Box>
              ) : (
                <>
                  <Box
                    pad={ size === 'small' ? 'large' : 'medium' }
                    border={{
                      side: 'bottom',
                      color: 'light-4'
                    }}
                  >
                    <Text size='large' textAlign='center'>
                      {detail.question}
                    </Text>
                  </Box>

                  <Box
                    pad={ size === 'small' ? 'large' : 'medium' }
                    gap={ size === 'small' ? 'medium' : 'small' }
                  >
                    {detail.options.map((item, index) => {
                      return (
                        <RadioButton
                          name='type'
                          key={index}
                          value={item.id}
                          label={
                            <Text size='small'>{item.op}</Text>
                          }
                          checked={chosenOption === item.id.toString()}
                          onChange={event =>
                            changeChosenOption(event.target.value)
                          }
                        />
                      )
                    })}
                  </Box>

                  <Box
                    pad={
                      size === 'small' ? 'large' : 'medium'
                    }
                  >
                    <Button
                      color={detail.color}
                      primary
                      label={
                        <Text color='white' weight='normal'>
                          { loadingPostForm ? 'REGISTRANDO...' : 'PARTICIPAR' }
                        </Text>
                      }
                      disabled={ loadingPostForm || !chosenOption }
                      onClick={() => {
                        const cDate = new Date();

                        this.handlerPost({
                          payload: {
                            identifier,
                            createdAt: cDate.toISOString(),
                            option: chosenOption,
                          },
                          header: {
                            identifier,
                            expirationDate: new Date(cDate.getFullYear(), cDate.getMonth(), cDate.getDate(), cDate.getHours(), cDate.getMinutes() + 1, cDate.getSeconds()).toISOString(),
                          }
                        })
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>

            <Box
              width='medium'
              align='center'
            >
              <Anchor
                color='white'
                label='Saiba mais sobre as enquetes'
                href='https://suporte.gerenciameumandato.com.br/informacoes-sobre-enquetes/40'
                size='small'
                target='_blank'
              />
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ homeReducer: { details, formVote } }) => ({
  detail: details.detail,
  getDetailError: details.getDetailError,

  chosenOption: formVote.chosenOption,
  postDataSuccess: formVote.postDataSuccess,
  loadingPostForm: formVote.loadingPostForm,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    clearDetails,
    createAnswer,
    changeChosenOption,
    loadData,
  }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
