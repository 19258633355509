const setCookie = (key, value, options = {}) => {
  return (document.cookie = [
    encodeURIComponent(key), '=', String(value),
    options.expires ? '; expires=' + options.expires.toUTCString() : '',
    options.path    ? '; path=' + options.path : '',
    options.domain  ? '; domain=' + options.domain : '',
    options.secure  ? '; secure' : ''
  ].join(''));
};

export default setCookie;
