import { combineReducers } from 'redux';
import detailsReducer from './details';
import formVoteReducer from './form-vote';

const homeReducer = combineReducers({
  details: detailsReducer,
  formVote: formVoteReducer,
})

export default homeReducer;
